'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var isMobile = /mobile/i.test(window.navigator.userAgent);
var utils = {
    secondToTime: function secondToTime(second) {
        var add0 = function add0(num) {
            return num < 10 ? '0' + num : '' + num;
        };
        var hour = Math.floor(second / 3600);
        var min = Math.floor((second - hour * 3600) / 60);
        var sec = Math.floor(second - hour * 3600 - min * 60);
        return (hour > 0 ? [
            hour,
            min,
            sec
        ] : [
            min,
            sec
        ]).map(add0).join(':');
    },
    getElementViewLeft: function getElementViewLeft(element) {
        var actualLeft = element.offsetLeft;
        var current = element.offsetParent;
        var elementScrollLeft = document.body.scrollLeft + document.documentElement.scrollLeft;
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            while (current !== null) {
                actualLeft += current.offsetLeft;
                current = current.offsetParent;
            }
        } else {
            while (current !== null && current !== element) {
                actualLeft += current.offsetLeft;
                current = current.offsetParent;
            }
        }
        return actualLeft - elementScrollLeft;
    },
    getScrollPosition: function getScrollPosition() {
        return {
            left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
            top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        };
    },
    setScrollPosition: function setScrollPosition(_ref) {
        var _ref$left = _ref.left, left = _ref$left === undefined ? 0 : _ref$left, _ref$top = _ref.top, top = _ref$top === undefined ? 0 : _ref$top;
        if (this.isFirefox) {
            document.documentElement.scrollLeft = left;
            document.documentElement.scrollTop = top;
        } else {
            window.scrollTo(left, top);
        }
    },
    isMobile: isMobile,
    isFirefox: /firefox/i.test(window.navigator.userAgent),
    isChrome: /chrome/i.test(window.navigator.userAgent),
    storage: {
        set: function set(key, value) {
            localStorage.setItem(key, value);
        },
        get: function get(key) {
            return localStorage.getItem(key);
        }
    },
    cumulativeOffset: function cumulativeOffset(element) {
        var top = 0, left = 0;
        do {
            top += element.offsetTop || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);
        return {
            top: top,
            left: left
        };
    },
    nameMap: {
        dragStart: isMobile ? 'touchstart' : 'mousedown',
        dragMove: isMobile ? 'touchmove' : 'mousemove',
        dragEnd: isMobile ? 'touchend' : 'mouseup'
    }
};
exports.default = utils;